<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Sequence <span>Assign Contacts</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleAssignContacts" v-slot="{ errors }" ref="assign-contacts-sequence">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Select Users</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.contact }">
                                    <Field autocomplete="off" name="contact" v-model="form.contacts" rules="required">
                                        <multiselect
                                            v-model="form.contacts"
                                            label="name"
                                            value-prop="id"
                                            mode="tags"
                                            :options="contacts"
                                            :searchable="true"
                                            :internal-search="false"
                                            @search-change="searchContact"
                                            placeholder="Select"
                                            trackBy="search_key"
                                        >
                                            <template v-slot:option="{ option }">
                                                <div class="multiselect-avatar">
                                                    <img class="character-option-icon" :src="option.photo">
                                                </div>
                                                <div class="multiselect-title-content">
                                                    <div class="multiselect-title">{{ option.name }}</div>
                                                    <div class="multiselect-sub-title">{{ option.email }}</div>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="contact" class="text-danger" />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button type="button" :disabled="loader" class="btn save_btn" @click="handleAssignContacts"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    export default {
        name: 'Sequence Assign Contact',

        data () {
            return {
                form: {
                    sequence_id: '',
                    contacts: [],
                },
            }
        },

        props: {
            modelValue: Boolean,
            sequence: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.getAllContacts({});
                    vm.form.contacts    = [];
                    vm.form.sequence_id = [vm.sequence.id];
                }
            },
        },

        components: {
            Form,
            Field,
            ErrorMessage,
        },

        computed: mapState ({
            contacts: state => state.contactModule.allContacts,
            loader: state => state.sequenceModule.sequenceComponentLoader,
        }),

        methods: {
            ...mapActions({
                getAllContacts: 'contactModule/getAllContacts',
                assignContacts: 'sequenceModule/assignContacts',
                getSequences: 'sequenceModule/getSequences',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            searchContact (search) {
                const vm = this;

                vm.getAllContacts({ search });
            },

            handleAssignContacts () {
                const vm = this;

                const assignContactsForm = vm.$refs['assign-contacts-sequence'];

                assignContactsForm.validate().then((result) => {
                    if (result.valid) {
                        vm.form.setFieldError = assignContactsForm.setFieldError;

                        vm.assignContacts(vm.form).then((result) => {
                            const params =  {
                                                per_page: 6,
                                                page: 1,
                                                search: '',
                                                order_by: 'ordering',
                                                order: 'desc',
                                                folder_id: null,
                                            };

                            vm.getSequences(params);

                            vm.closeModal();
                        })
                    }
                })
            }
        }

    }
</script>
